import * as React from 'react'
import { Link } from 'gatsby'
import { useState, useEffect } from 'react'
import { shapeContainer, square, circle, triangle } from './shapes.module.css'



const Shape = ({ x, y, s, a, children }) => {
  let starter=square, second=circle, third=triangle;
  let randSelect = Math.floor(Math.random()*3);
  if (s>=0) { randSelect=parseInt(s); }
  if (randSelect===1) { // circle
    starter=circle; second=triangle; third=square;
  } else if (randSelect===2) { // triangle
    starter=triangle; second=square; third=circle;
  }

  const [phase,setPhase] = useState(starter);
  useEffect(()=> {
    run();
    return () => { // cleanup
      var highestTimeoutId = setTimeout(";");
      for (var i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i);
      }
    }
  }, []);
  const run = () => {
    setTimeout(() => {
      setPhase(second);
    },1000);
    setTimeout(() => {
      setPhase(third);
    },2000);
    setTimeout(() => {
      setPhase(starter);
      run();
    },3000);
  }

  let shapeSVG = (
    <svg id="shapeSVG" viewBox="-5 -5 110 110" xmlns="http://www.w3.org/2000/svg">
      <path className={phase} stroke="rgba(150,150,150,0.4)" strokeWidth="10" fill="transparent">
      </path>
    </svg>
  );
  let component = a ? (
    <div className={shapeContainer} style={{transform:`translate(${x}px,${y}px)`}}>
      {(a[0]==="/") ?
        <Link to={a}>{shapeSVG}</Link> :
        <a href={a} target="_blank" rel="noreferrer">{shapeSVG}</a>
      }
    </div>
  ) : (
    <div className={shapeContainer} style={{transform:`translate(${x}px,${y}px)`}}>
      {shapeSVG}
    </div>
  );

  return (
    component
  )
}

export default Shape
