import * as React from 'react'
import { homeTitle } from '../components/layout.module.css'
import Shape from '../components/shapes'

const Banner = ({ language }) => {
  if (language==="spanish") {
    let titulo = "juanpintado.com";
    let xs = [9,31,58,88,135,164,179,210,234,264,296,345,350,383,418];
    let ys = [50,40,50,40,50,40,50,40,50,40,50,40,40,50,40];
    return (
      <svg viewBox="0 0 470 60" xmlns="http://www.w3.org/2000/svg">
        {xs.map((x,i) => (
          <text x={x} y={ys[i]} fill="var(--color-front)" key={x}>{titulo[i]}</text>
        ))}
      </svg>
    )
  } else {
    let titulo = "evangedrich.com";
    let xs = [9,39,58,88,135,167,194,225,254,264,296,345,350,383,418];
    let ys = [50,40,50,40,50,40,50,40,50,40,50,40,40,50,40];
    return (
      <svg viewBox="0 0 470 60" xmlns="http://www.w3.org/2000/svg">
        {xs.map((x,i) => (
          <text x={x} y={ys[i]} fill="var(--color-front)" key={x}>{titulo[i]}</text>
        ))}
      </svg>
    )
  }
}

const ShapeDisplay = () => {
  let xVals=[-70,-40,0,40,70,-70,-40,0,40,70], yVals=[50,80,90,80,50,-50,-80,-90,-80,-50], sVals=[0,1,2,0,1,2,0,1,2,0,1,2];
  for (let i=0; i<yVals.length; i++) {
    if (yVals[i]>=0) {yVals[i]=yVals[i]+5;}
    else {yVals[i]=yVals[i]-5;}
  }
  return (
    <div>
      {xVals.map((x,i) => {
        return (
          <Shape x={(x)} y={yVals[i]} s={sVals[i]} key={x.toString()+yVals[i].toString()} a={(i<data.length)?data[i].link:undefined}></Shape>
        )
      })}
      {xVals.map((x,i) => {
        return (
          <Shape x={(x*1.8)} y={(yVals[i]*1.8)} s={sVals[i+2]} key={x.toString()+yVals[i].toString()} a={((i+9)<data.length)?data[i+9].link:undefined}></Shape>
        )
      })}
      <Shape x={155} y={45} s={1} key="shape21"></Shape>
      <Shape x={155} y={-45} s={0} key="shape22" a="/links"></Shape>
      <Shape x={-155} y={45} s={1} key="shape23"></Shape>
      <Shape x={-155} y={-45} s={0} key="shape24"></Shape>
    </div>
  )
}

const data = [
  {
    link: "https://youtu.be/ZBiHzjj-yBE",
    title: "Kabir & the Seeker",
    description: ""
  },
  {
    link: "https://youtu.be/fz5xS4v3-ho",
    title: "Lila Downs on Identity",
    description: ""
  },
  {
    link: "https://en.wikipedia.org/wiki/Cultural_hegemony",
    title: "Cultural hegemony",
    description: ""
  },
  {
    link: "https://lithub.com/what-does-immersing-yourself-in-a-book-do-to-your-brain/",
    title: "Reading & Empathy",
    description: ""
  },
  {
    link: "https://en.wikiquote.org/wiki/Zhuangzi#:~:text=Now%20I%20do%20not%20know,from%20morning%20to%20night...",
    title: "Zhuangzhi's Butterfly Dream",
    description: ""
  },
  {
    link: "https://www.youtube.com/watch?v=MASBIB7zPo4",
    title: "What is consciousness?",
    description: ""
  },
  {
    link: "http://ww3.haverford.edu/psychology/ddavis/p109g/proust.html",
    title: "Proust's madeleine",
    description: ""
  },
  {
    link: "https://www.youtube.com/watch?v=QwXAqIaUahI",
    title: "Bill Evans on Creativity & Self Teaching",
    description: ""
  },
  {
    link: "https://faculty.washington.edu/smcohen/320/cave.htm",
    title: "Plato's Cave Allegory",
    description: ""
  },
];

const IndexPage = () => {
  const isBrowser = () => typeof document !== "undefined";
  let languageSetting = isBrowser() ? localStorage.getItem('language') : "off";
  let language = (languageSetting==="on") ? 'spanish' : 'english';
  if (0) {console.log(0)}
  return (
    <main>
      <title>Evan Gedrich</title>
      <h1 className={homeTitle} id="indexBanner">
        <Banner language={language}></Banner>
        <ShapeDisplay></ShapeDisplay>
      </h1>

    </main>
  )
}

export default IndexPage
